<template>
    <div>
        <FormView @submit="handleSubmit">
            <div class="row">
                <div class="col-12">
                    <div :class="`alert alert-${msgType}`" role="alert" v-if="msg">
                        {{msg}}
                    </div>
                </div>
                <div class="col-12 group-form">
                    <TextArea
                            name="content"
                            v-model="comment.content"
                        />
                </div>
                <div class="col-12 mt-2 text-center">
                    <button 
                        type="submit" 
                        class="btn-submit"
                        :disabled="isSending">
                        {{$t('inquiry.report_to_admin')}} {{ isSending? "...": "" }}
                    </button>
                </div>
            </div>
        </FormView>
        <Popup
            :isVisible="this.isVisible"
            :confirmButton="$t('inquiry.report.ok')"
            :hideCancelButton="true"
            @confirm="actionConfirm"
            @cancel="actionConfirm"
        >
            {{$t('inquiry.report.report_is_sent')}}
        </Popup>
    </div>
</template>
<script>
import { mapActions } from 'vuex';

export default {
    name: "FormReport",
    data(){
        return {
            comment: {
                content: ""
            },
            isSending: false,
            msgType: "danger",
            msg: "",
            isVisible: false
        };
    },
    props: {
        item: { default: null }
    },
    methods: {
        ...mapActions({
            reportTalkRoom: "talkRoom/reportTalkRoom"
        }),
        async handleSubmit() {
            if (this.isSending)
                return;
            this.isSending = true;
            await this.reportTalkRoom({
                id: this.item.talk_room_id,
                data: this.comment,
                cb: (response) => {
                    const { data, meta } = response;
                    if (meta && meta.code == 200 && data.status !== false) {
                        this.msg = "";
                        this.isVisible = true;
                    }
                    else {
                        if (data.errors) {
                            let field = Object.keys(data.errors)[0];
                            this.msg = this.$t(data.errors[field][0], {
                                _field_: "お問い合わせ詳細"
                            });
                        }
                        else {
                            if (data && data.message) {
                                this.msg = data.message;
                            }
                            else {
                                this.msg = this.$t("talk_room.report.failed");
                            }
                        }
                        this.msgType = "danger";
                    }
                }
            });
            this.isSending = false;
        },
        actionConfirm(){
            this.isVisible = false;
            this.$router.back();
        }
    },
    watch: {
        item(data){
            this.item = data;
        }
    }
}
</script>